<template>
    			<!-- Most popular Categories -->
			<section class="most-popular most-popular-five">
				<div class="container">
					<div class="section-header section-head-left aos " data-aos="fade-up">
						<div class="section-head-five">
							<h4><span></span>Categories</h4>
							<h2>Browse Courses By Categories</h2>
						</div>
						<div class="view-all ">
							<router-link to="/mentee/mentor-search">View All Category</router-link>
						</div>
					</div>
					<div class="popular-categories aos " data-aos="fade-up">
						<div class="row">
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-01.svg" alt=""></span>
											<span class="cat-rat">5.0</span>
										</div>
										<div class="categories-text">
											<h4>Design & Development</h4>
											<h5><span>300+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-02.svg" alt=""></span>
											<span class="cat-rat">4.7</span>
										</div>
										<div class="categories-text ">
											<h4>Digital Marketing</h4>
											<h5><span>400+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-03.svg" alt=""></span>
											<span class="cat-rat">4.4</span>
										</div>
										<div class="categories-text ">
											<h4>Marketing & Communication</h4>
											<h5><span>500+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-01.svg" alt=""></span>
											<span class="cat-rat">4.8</span>
										</div>
										<div class="categories-text ">
											<h4>IT & Security</h4>
											<h5><span>350+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-04.svg" alt=""></span>
											<span class="cat-rat">4.2</span>
										</div>
										<div class="categories-text ">
											<h4>Finance Management</h4>
											<h5><span>450+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-01.svg" alt=""></span>
											<span class="cat-rat">4.3</span>
										</div>
										<div class="categories-text ">
											<h4>Business & Consulting</h4>
											<h5><span>550+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-05.svg" alt=""></span>
											<span class="cat-rat">4.0</span>
										</div>
										<div class="categories-text ">
											<h4>SEO & Security</h4>
											<h5><span>200+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
							<div class="col-lg-3 col-md-6">
								<router-link to="/mentee/mentor-search">
									<div class="sub-categories-five">
										<div class="categories-five-img">
											<span><img src="@/assets/img/icon/course-cat-01.svg" alt=""></span>
											<span class="cat-rat">4.5</span>
										</div>
										<div class="categories-text ">
											<h4>Self Development</h4>
											<h5><span>250+ </span> Courses Available</h5>
										</div>
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Most popular Categories -->
    </template>