<template>
  <div class="main-wrapper main-wrapper-five">
    <layout-header-five />
    <HomeBannerFive />
    <CategoriesFive />
    <TrendingCourseFive />
    <FeaturedInstructorFive />
    <FeedbakFive />
    <KnowledgeFive />
    <MostTrendingCourseFive />
    <NewCourseFive />
    <FaqFive />
    <BlogFive />
    <layout-footer-five />
  </div>
</template>
