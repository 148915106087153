<template>
    <div class="main-wrapper">
        <layout-header1></layout-header1>
        <breadcrumb21></breadcrumb21>
<!-- Page Content -->
<div class="content" style="min-height: 345.2px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h5>Blank Page</h5>
            </div>
        </div>
    </div>

</div>		
<!-- /Page Content -->
<layout-footer></layout-footer>
        </div>
</template>