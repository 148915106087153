<template>
  <section class="banner-feature-sec work-eight-sec">
    <div class="container">
      <div class="section-bg-imgs">
        <img
          src="@/assets/img/bg/arrow-bg.png"
          class="img-fluid arrow-bg"
          alt=""
        />
        <img
          src="@/assets/img/bg/index-six-bg-01.png"
          class="img-fluid arrow-bgtwo"
          alt=""
        />
      </div>
      <div class="heder-eight aos" data-aos="fade-up">
        <div class="section-head-eight">
          <h2>How It <span> Works?</span></h2>
          <p>
            Are you looking to join online institutions? Now it's very simple,
            Sign up with mentoring
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div
            class="feature-box feature-box-eight text-center aos"
            data-aos="fade-up"
          >
            <div class="feature-header">
              <div class="feature-icon">
                <span class="circle"></span>
                <i><img src="@/assets/img/icon-1.png" alt="" /></i>
              </div>
              <div class="feature-cont">
                <div class="feature-text">Sign up</div>
              </div>
            </div>
            <p class="mb-0">
              Are you looking to join online Learning? Now it's very simple, Now
              Sign up
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="feature-box feature-box-eight text-center aos"
            data-aos="fade-up"
          >
            <div class="feature-header">
              <div class="feature-icon">
                <span class="circle"></span>
                <i><img src="@/assets/img/icon-2.png" alt="" /></i>
              </div>
              <div class="feature-cont">
                <div class="feature-text">Collaborate</div>
              </div>
            </div>
            <p class="mb-0">
              Collaborate on your own timing, by scheduling with mentor booking
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="feature-box feature-box-eight text-center aos"
            data-aos="fade-up"
          >
            <div class="feature-header">
              <div class="feature-icon">
                <span class="circle"></span>
                <i><img src="@/assets/img/icon-3.png" alt="" /></i>
              </div>
              <div class="feature-cont">
                <div class="feature-text">Improve & Get Back</div>
              </div>
            </div>
            <p class="mb-0">
              You can gather different skill set, and you can become mentor too
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
