export default [
  {
    path: 'about-us',
    name: 'about-us',
    component: () => import('./pages/index/Index.vue'),
    // meta: {
    //   middleware: [

    //   ],
    // }
  }
]
