<template>
  <section class="want-mentor">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="want-mentor-content">
            <div class="heder-eight aos" data-aos="fade-up">
              <div class="section-head-eight">
                <h2>Want To Become <span>A Mentor?</span></h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                  aenean accumsan bibendum
                </p>
                <router-link to="/pages/login" class="join-now">Join Now</router-link>
              </div>
              <div class="bg-imgs">
                <img
                  src="@/assets/img/bg/index-six-bg-02.png"
                  class="blue-arrow"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="become-mentor-img">
            <img src="@/assets/img/become-mentor.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
