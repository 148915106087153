<template>
  <div class="main-wrapper main-wrapper-eight">
    <layout-header-eight />
    <HomeBannerEight />
    <CategoriesEight />
    <FeaturedCoursesEight />
    <RatingEight />
    <FaqEight />
    <BlogEight />
    <layout-footer-eight />
  </div>
</template>
