<template>
  <!-- Student Feedback -->
  <section class="student-feedback">
    <div class="container">
      <div class="aos" data-aos="fade-up">
        <div class="heder-eight aos" data-aos="fade-up">
          <div class="section-head-eight">
            <h2>Our Latest <span>Reviews </span></h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              aenean accumsan bibendum
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="student-profile-five profile-six">
            <div class="student-profile-text">
              <h3>Lorem ipsum dolor</h3>
              <div class="quatation-para">
                <span
                  ><img src="@/assets/img/icon/quatation-white.svg" alt=""
                /></span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  vitae neque metus. Vivamus consectetur ultricies commodo.
                  Pellentesque at nisl sit amet.
                </p>
              </div>
              <div class="student-profile-viewfive">
                <div class="student-name">
                  <div class="student-img">
                    <span><img src="@/assets/img/user/user.jpg" alt="" /></span>
                  </div>
                  <div class="student-name-info">
                    <h3>
                      <router-link to="/mentor/reviews">Steve</router-link>
                    </h3>
                    <span> Designer</span>
                  </div>
                </div>
                <div class="rating">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="student-profile-five profile-six">
            <div class="student-profile-text">
              <h3>Lorem ipsum dolor</h3>
              <div class="quatation-para">
                <span
                  ><img src="@/assets/img/icon/quatation-white.svg" alt=""
                /></span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  vitae neque metus. Vivamus consectetur ultricies commodo.
                  Pellentesque at nisl sit amet.
                </p>
              </div>
              <div class="student-profile-viewfive">
                <div class="student-name">
                  <div class="student-img">
                    <span
                      ><img src="@/assets/img/user/user11.jpg" alt=""
                    /></span>
                  </div>
                  <div class="student-name-info">
                    <h3>
                      <router-link to="/mentor/reviews">Designer</router-link>
                    </h3>
                    <span> Developer</span>
                  </div>
                </div>
                <div class="rating">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="student-profile-five profile-six">
            <div class="student-profile-text">
              <h3>Lorem ipsum dolor</h3>
              <div class="quatation-para">
                <span
                  ><img src="@/assets/img/icon/quatation-white.svg" alt=""
                /></span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  vitae neque metus. Vivamus consectetur ultricies commodo.
                  Pellentesque at nisl sit amet.
                </p>
              </div>
              <div class="student-profile-viewfive">
                <div class="student-name">
                  <div class="student-img">
                    <span
                      ><img src="@/assets/img/user/user12.jpg" alt=""
                    /></span>
                  </div>
                  <div class="student-name-info">
                    <h3>
                      <router-link to="/mentor/reviews">Mathan</router-link>
                    </h3>
                    <span> Marketer</span>
                  </div>
                </div>
                <div class="rating">
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                  <i class="fa fa-star filled"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Student Feedback -->
</template>
