<template>
  <div class="main-wrapper">
    <layout-header1></layout-header1>
    <breadcrumb14></breadcrumb14>

    <!-- Page Content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7 col-lg-8">
            <div class="card">
              <div class="card-body">
                <!-- Checkout Form -->
                <form>
                  <!-- Personal Information -->
                  <div class="info-widget">
                    <h4 class="card-title">Personal Information</h4>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>First Name</label>
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Last Name</label>
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Email</label>
                          <input class="form-control" type="email" />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Phone</label>
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                    </div>
                    <div class="exist-customer">
                      Existing Customer? <a href="#">Click here to login</a>
                    </div>
                  </div>
                  <!-- /Personal Information -->

                  <div class="payment-widget">
                    <h4 class="card-title">Payment Method</h4>

                    <!-- Credit Card Payment -->
                    <div class="payment-list">
                      <label class="payment-radio credit-card-option">
                        <input type="radio" name="radio" checked />
                        <span class="checkmark"></span>
                        Credit card
                      </label>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group card-label">
                            <label for="card_name">Name on Card</label>
                            <input
                              class="form-control"
                              id="card_name"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group card-label">
                            <label for="card_number">Card Number</label>
                            <input
                              class="form-control"
                              id="card_number"
                              placeholder="1234  5678  9876  5432"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group card-label">
                            <label for="expiry_month">Expiry Month</label>
                            <input
                              class="form-control"
                              id="expiry_month"
                              placeholder="MM"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group card-label">
                            <label for="expiry_year">Expiry Year</label>
                            <input
                              class="form-control"
                              id="expiry_year"
                              placeholder="YY"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group card-label">
                            <label for="cvv">CVV</label>
                            <input class="form-control" id="cvv" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /Credit Card Payment -->

                    <!-- Paypal Payment -->
                    <div class="payment-list">
                      <label class="payment-radio paypal-option">
                        <input type="radio" name="radio" />
                        <span class="checkmark"></span>
                        Paypal
                      </label>
                    </div>
                    <!-- /Paypal Payment -->

                    <!-- Terms Accept -->
                    <div class="terms-accept">
                      <div class="custom-checkbox">
                        <input type="checkbox" id="terms_accept" />
                        <label for="terms_accept"
                          >I have read and accepted
                          <a href="#">Terms &amp; Conditions</a></label
                        >
                      </div>
                    </div>
                    <!-- /Terms Accept -->

                    <!-- Submit Section -->
                    <div class="submit-section mt-4">
                      <button
                        type="button"
                        @click="$router.push('/mentee/booking-success')"
                        class="btn btn-primary submit-btn"
                      >
                        Confirm and Pay
                      </button>
                    </div>
                    <!-- /Submit Section -->
                  </div>
                </form>
                <!-- /Checkout Form -->
              </div>
            </div>
          </div>

          <div class="col-md-5 col-lg-4 theiaStickySidebar">
            <div class="stickysidebar">
              <!-- Booking Summary -->
              <div class="card booking-card">
                <div class="card-header">
                  <h4 class="card-title">Booking Summary</h4>
                </div>
                <div class="card-body">
                  <!-- Booking Mentee Info -->
                  <div class="booking-user-info">
                    <router-link
                      to="/mentee/mentor-profile"
                      class="booking-user-img"
                    >
                      <img
                        src="@/assets/img/website/user/user2.jpg"
                        alt="User Image"
                      />
                    </router-link>
                    <div class="booking-info">
                      <h4>
                        <router-link to="/mentee/mentor-profile"
                          >Darren Elder</router-link
                        >
                      </h4>
                      <div class="rating">
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star"></i>
                        <span class="d-inline-block average-rating">35</span>
                      </div>
                      <div class="mentor-details">
                        <p class="user-location">
                          <i class="fas fa-map-marker-alt"></i> Newyork, USA
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Booking Mentee Info -->

                  <div class="booking-summary">
                    <div class="booking-item-wrap">
                      <ul class="booking-date">
                        <li>Date <span>16 Nov 2019</span></li>
                        <li>Time <span>10:00 AM</span></li>
                      </ul>
                      <ul class="booking-fee">
                        <li>Consulting Fee <span>$100</span></li>
                        <li>Booking Fee <span>$10</span></li>
                        <li>Video Call <span>$50</span></li>
                      </ul>
                      <div class="booking-total">
                        <ul class="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span class="total-cost">$160</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Booking Summary -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
