<template>
  <!-- Blogs -->
  <section
    class="section trending-courses trending-courses-three popular-course-three popular-course-five five-course-card blog-five"
  >
    <div class="container">
      <div class="section-header section-head-left aos" data-aos="fade-up">
        <div class="section-head-five">
          <h4><span></span>Get the latest News</h4>
          <h2>News And Blog</h2>
          <p>
            Do you want to move on next step? Choose your most popular leaning
            mentors, it will help you to achieve your professional goals.
          </p>
        </div>
        <div class="view-all">
          <router-link to="/blog/blog-list">View All Blogs</router-link>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-11.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="feature-texts">
                    <span>Marketing</span>
                  </div>
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 15 Min to
                    read
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >39 Strengths and Weaknesses To Discuss in a Courses</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star rating-two rating-five align-items-center"
                >
                  <div class="rating-img">
                    <img src="@/assets/img/user/profile-01.jpg" alt="" />
                  </div>
                  <h5 class="user-name">
                    <router-link to="/mentee/mentor-profile">Casandra</router-link>
                    <span> 09 Jan 2023</span>
                  </h5>
                  <div class="course-btn">
                    <router-link to="/mentor/blog-details">Read-more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-12.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="feature-texts">
                    <span>Marketing</span>
                  </div>
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 20 Min to
                    read
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >How to Make a Perfect CV That Attracts the Attention</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star rating-two rating-five align-items-center"
                >
                  <div class="rating-img">
                    <img src="@/assets/img/user/profile-02.jpg" alt="" />
                  </div>
                  <h5 class="user-name">
                    <router-link to="/mentee/mentor-profile">Steve</router-link> <span> 08 Jan 2023</span>
                  </h5>
                  <div class="course-btn">
                    <router-link to="/mentor/blog-details">Read-more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-13.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="feature-texts">
                    <span>Marketing</span>
                  </div>
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 25 Min to
                    read
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >Interview Question: Why Dont You Have a Degree?</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star rating-two rating-five align-items-center"
                >
                  <div class="rating-img">
                    <img src="@/assets/img/user/profile-03.jpg" alt="" />
                  </div>
                  <h5 class="user-name">
                    <router-link to="/mentee/mentor-profile">Harvey</router-link> <span> 07 Jan 2023</span>
                  </h5>
                  <div class="course-btn">
                    <router-link to="/mentor/blog-details">Read-more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blogs -->
</template>
