<template>
  <div class="main-wrapper main-wrapper-three">
    <layout-header-three />
    <HomeBannerThree />
    <CategoriesThree />
    <WorkThree />
    <TrendingCourseThree />
    <MentorThree />
    <RatingThree />
    <PopularCourseThree />
    <TestimonialThree />
    <JobLocationThree />
    <NewCourseThree />
    <FaqThree />
    <BlogThree />
    <layout-footer-three />
  </div>
</template>
