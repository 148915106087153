<template>
  <!-- Most Trending  Courses -->
  <section
    class="section trending-courses trending-courses-three popular-course-three popular-course-five"
  >
    <div class="container">
      <div class="heder-eight aos" data-aos="fade-up">
        <div class="section-head-eight">
          <h2>Latest <span> Blogs</span></h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
            accumsan bibendum
          </p>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-11.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="rating-img d-flex align-items-center me-0">
                    <img
                      src="@/assets/img/user/profile-01.jpg"
                      class="me-2"
                      alt=""
                    />
                    <h5 class="user-name blog-user-name m-0">
                      <router-link to="/mentee/mentor-profile">Steve </router-link>
                    </h5>
                  </div>
                  <span class="average-rating rate-point">
                    <img
                      src="@/assets/img/icon/calendar.svg"
                      class="me-2"
                      alt=""
                    />
                    09 Jan 2023
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >How to Make a Perfect CV That Attracts the
                    Attention</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star blog-latest rating-two rating-five align-items-center"
                >
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 20 Min
                    to read
                  </span>
                  <span class="card-arrow ms-auto">
                    <router-link to="/mentor/blog-details"
                      ><img
                        src="@/assets/img/icon/white-arrow-up.svg"
                        class="me-0"
                        alt=""
                    /></router-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-12.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="rating-img d-flex align-items-center me-0">
                    <img
                      src="@/assets/img/user/profile-02.jpg"
                      class="me-2"
                      alt=""
                    />
                    <h5 class="user-name blog-user-name m-0">
                      <router-link to="/mentee/mentor-profile">Harvey</router-link>
                    </h5>
                  </div>
                  <span class="average-rating rate-point">
                    <img
                      src="@/assets/img/icon/calendar.svg"
                      class="me-2"
                      alt=""
                    />
                    20 Jan 2023
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >39 Strengths and Weaknesses To Discuss in a
                    Courses</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star blog-latest rating-two rating-five align-items-center"
                >
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 25 Min
                    to read
                  </span>
                  <span class="card-arrow ms-auto">
                    <router-link to="/mentor/blog-details"
                      ><img
                        src="@/assets/img/icon/white-arrow-up.svg"
                        class="me-0"
                        alt=""
                    /></router-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="course-box course-box-five">
            <div class="product">
              <div class="product-img trend-course">
                <router-link to="/mentor/blog-details">
                  <img
                    class="img-fluid"
                    alt=""
                    src="@/assets/img/course/trending-course-13.jpg"
                    width="600"
                    height="300"
                  />
                </router-link>
              </div>
              <div class="product-content">
                <div class="rating rate-star rating-star-five">
                  <div class="rating-img d-flex align-items-center me-0">
                    <img
                      src="@/assets/img/user/profile-03.jpg"
                      class="me-2"
                      alt=""
                    />
                    <h5 class="user-name blog-user-name m-0">
                      <router-link to="/mentee/mentor-profile">Casandra</router-link>
                    </h5>
                  </div>
                  <span class="average-rating rate-point">
                    <img
                      src="@/assets/img/icon/calendar.svg"
                      class="me-2"
                      alt=""
                    />
                    15 Jan 2023
                  </span>
                </div>
                <h3 class="title">
                  <router-link to="/mentor/blog-details"
                    >Interview Question: Why Dont You Have a
                    Degree?</router-link
                  >
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, et alii clita tritani per. Vix ut
                  vidisse pertinacia, ius ut maiorum omittam....
                </p>
                <div
                  class="rating rating-star blog-latest rating-two rating-five align-items-center"
                >
                  <span class="average-rating rate-point">
                    <img src="@/assets/img/icon/time-icon.svg" alt="" /> 15 Min
                    to read
                  </span>
                  <span class="card-arrow ms-auto">
                    <router-link to="/mentor/blog-details"
                      ><img
                        src="@/assets/img/icon/white-arrow-up.svg"
                        class="me-0"
                        alt=""
                    /></router-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Most Trending Courses -->
</template>
