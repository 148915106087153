<template>
  <div class="main-wrapper main-wrapper-six">
    <layout-header-six />
    <HomeBannerSix />
    <CategoriesSix />
    <FeatureSix />
    <TrustedSix />
    <MostTrendingCourseSix />
    <SKillsSix />
    <MostTrendingCourseTwoSix />
    <FeedbackSix />
    <FaqSix />
    <PlaceSix />
    <MostTrendingCourseThreeSix />
    <MentorSix />
    <layout-footer-six />
  </div>
</template>
