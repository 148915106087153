<template>
<!-- Categories -->
    <div class="card category-widget">
        <div class="card-header">
            <h4 class="card-title">Blog Categories</h4>
        </div>
        <div class="card-body">
            <ul class="categories">
                <li><a href="#">HTML <span>(62)</span></a></li>
                <li><a href="#">Css <span>(27)</span></a></li>
                <li><a href="#">Java Script <span>(41)</span></a></li>
                <li><a href="#">Photoshop <span>(16)</span></a></li>
                <li><a href="#">Wordpress <span>(55)</span></a></li>
                <li><a href="#">VB <span>(07)</span></a></li>
            </ul>
        </div>
    </div>
<!-- /Categories -->
</template>