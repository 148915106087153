<template>
  <div class="main-wrapper">
    <layout-header />
    <HomeBanner />
    <MentoringFlow />
    <PopularMentor />
    <LearningPath />
    <Review />
    <Blogs />
    <PopularLocation />
    <Faq />
    <Statistics />
    <layout-footer />
  </div>
</template>

<script>

</script>
