<template>
  <!-- Footer -->
  <footer class="footer footer-one footer-five">
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-4">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Company</h2>
              <ul>
                <li><a href="">What we offer</a></li>
                <li><a href="">Careers</a></li>
                <li><a href="">Press</a></li>
                <li><a href="">Become a Mentor</a></li>
                <li><a href="">Affilates</a></li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-2 col-md-4">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Community</h2>
                    <ul>
                <li>
                  <router-link to="/mentee/mentor-search">Learners</router-link>
                </li>
                <li><router-link to="/pages/login">Partners</router-link></li>
                <li>
                  <router-link to="/pages/register">Developers</router-link>
                </li>
                <li><router-link to="/booking">Free Courses</router-link></li>
                <li><router-link to="/mentee/index">Affilates</router-link></li>
                <li><router-link to="/mentee/index">Blogs</router-link></li>
                <li>
                  <router-link to="/mentee/index">Become a Mentee</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>

          <div class="col-lg-2 col-md-4">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">More</h2>
              <ul>
                <li>
                  <router-link to="/mentor/appointments"
                    >Appointments</router-link
                  >
                </li>
                <li><router-link to="/mentor/chat">Chat</router-link></li>
                <li><router-link to="/pages/login">Login</router-link></li>
                <li>
                  <router-link to="/pages/register">Register</router-link>
                </li>
                <li><router-link to="/mentor/index">Dashboard</router-link></li>
                <li><router-link to="/booking">Booking</router-link></li>
                <li>
                  <router-link to="/mentee/mentor-search"
                    >Search Mentors</router-link
                  >
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Quick Links</h2>
             <ul>
                <li>
                  <router-link to="/mentor/appointments"
                    >Terms & Conditions</router-link
                  >
                </li>
                <li>
                  <router-link to="/mentor/chat">Privacy Policy</router-link>
                </li>
                <li><router-link to="/pages/login">Contact us</router-link></li>
                <li>
                  <router-link to="/pages/register">Register</router-link>
                </li>
                <li><router-link to="/mentor/index">Sitemap</router-link></li>
                <li><router-link to="/mentor/index">Featured</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-subscribe bg-subscribe">
              <h2 class="footer-title">Subscribe</h2>
              <!-- Footer Subscribe -->
              <div class="footer-mail">
                <form action="/mentee/mentor-search">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Your email Addess"
                    />
                  </div>
                  <div class="input-group">
                    <a href="" class="btn btn-primary w-100">Subscribe</a>
                  </div>
                </form>
              </div>
              <!-- Footer Subscribe -->
              <p class="subscribe-text">
                Our goal is to translate the positive effects from
                revolutionizing how companies engage with their clients & their
                team.
              </p>
              <ul class="social-icon">
                <li>
                  <a
                    href="#"
                    class="social-icon-space d-flex align-items-center justify-content-center"
                    target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="#"
                    class="social-icon-space d-flex align-items-center justify-content-center"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="#"
                    class="social-icon-space d-flex align-items-center justify-content-center"
                    target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="#"
                    class="social-icon-space d-flex align-items-center justify-content-center"
                    target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright-border"></div>
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">&copy; 2023 Mentoring. All rights reserved.</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="term-privacy">
                <div class="bottom-links">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">About</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Become a Mentor</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Become a Mentee</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Pricing</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">All Courses</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Help</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
