<template>
    			<!-- Share Your Knowledge -->
			<section class="share-knowledge-three share-knowledge-five">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 d-flex align-items-center ">
							<div class="knowledge-img-three aos " data-aos="fade-up">
								<img src="@/assets/img/choose-mentor-02.png" class="img-fluid" alt="">
							</div>
						</div>
						<div class="col-lg-7 d-flex align-items-center">
							<div class="join-us-all aos " data-aos="fade-up">
								<div class="section-header section-head-left aos " data-aos="fade-up">
									<div class="section-head-five">
										<h4><span></span>About Us</h4>
										<h2>The experts in local and international mentors in all industries</h2>
										<p>Agent hen an unknown printer took a galley of type and scramble  it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
									</div>
								</div>
								<div class="join-two-face">
									<ul>
										<li><img src="@/assets/img/icon/tick-icon.svg" alt="">Outstanding Mentos</li>
										<li><img src="@/assets/img/icon/tick-icon.svg" alt="">More than 200+ Locations</li>
										<li><img src="@/assets/img/icon/tick-icon.svg" alt="">Specialised Courses</li>
										<li><img src="@/assets/img/icon/tick-icon.svg" alt="">Market-leading research</li>
									</ul>
									<div class="explore-more ">
										<a href="javascript:void(0);" class="btn bg-explore">Read More</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Share Your Knowledge -->
    </template>