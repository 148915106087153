<template>
  <!-- Work Flow -->
  <section class="section how-it-works-section">
    <div class="container">
      <div class="section-header-eight text-center aos" data-aos="fade-up">
        <span>Mentoring Flow</span>
        <h2>How does it works ?</h2>
        <p class="sub-title">
          Are you looking to join online institutions? Now it's very simple,
          Sign up with mentoring
        </p>
        <div class="sec-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="row justify-content-center feature-list">
        <div class="col-12 col-md-4 col-lg-3 aos" data-aos="fade-up">
          <div class="feature-grid text-center top-box">
            <div class="feature-header-eight">
              <div class="feature-icon-eight">
                <span class="circle bg-green"
                  ><i class="fas fa-sign-in-alt"></i
                ></span>
              </div>
              <div class="feature-cont">
                <div class="feature-text-eight">Sign up</div>
              </div>
            </div>
            <p>
              Are you looking to join online Learning? Now it's very simple, Now
              Sign up
            </p>
            <span class="text-green">01</span>
          </div>
        </div>
        <div
          class="col-12 col-md-4 col-lg-3 offset-lg-1 aos"
          data-aos="fade-up"
        >
          <div class="feature-grid text-center">
            <div class="feature-header-eight">
              <div class="feature-icon-eight">
                <span class="circle bg-blue"
                  ><i class="material-icons">accessibility</i></span
                >
              </div>
              <div class="feature-cont">
                <div class="feature-text-eight">Collaborate</div>
              </div>
            </div>
            <p>
              Collaborate on your own timing, by scheduling with mentor booking
            </p>
            <span class="text-blue">02</span>
          </div>
        </div>
        <div
          class="col-12 col-md-4 col-lg-3 offset-lg-1 aos"
          data-aos="fade-up"
        >
          <div class="feature-grid text-center top-box">
            <div class="feature-header-eight">
              <div class="feature-icon-eight">
                <span class="circle bg-yellow"
                  ><i class="material-icons">event_seat</i></span
                >
              </div>
              <div class="feature-cont">
                <div class="feature-text-eight">Improve & Get Back</div>
              </div>
            </div>
            <p>
              you can gather different skill set, and you can become mentor too
            </p>
            <span class="text-yellow">03</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Work Flow -->
</template>
