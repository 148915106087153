<template>
  <!-- Most popular Categories -->
  <section class="most-popular most-popular-five most-popular-six">
    <div class="container">
      <div class="heder-eight aos" data-aos="fade-up">
        <div class="section-head-eight">
          <h2>Browse Top <span>Categories</span></h2>
          <p>
            Do you want to move on next step? Choose your most popular leaning
            mentors, it will help you to achieve your professional goals.
          </p>
        </div>
      </div>
      <div class="popular-categories aos" data-aos="fade-up">
        <div class="row">
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-01.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text">
                  <h4>Design & Development</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-02.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text">
                  <h4>Digital Marketing</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-03.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text">
                  <h4>Marketing & Communication</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-04.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text seo-text">
                  <h4>SEO & Security</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-05.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text">
                  <h4>Business & Consulting</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6">
            <router-link to="/mentee/mentor-search">
              <div class="sub-categories-five sub-categories-eight">
                <div class="categories-five-img">
                  <span
                    ><img src="@/assets/img/categories/rec-06.jpg" alt=""
                  /></span>
                </div>
                <div class="categories-text">
                  <h4>Finance Management</h4>
                  <h5>Over <span> 2500 </span> Courses</h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Most popular Categories -->
</template>
