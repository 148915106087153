<template>
    			<!-- Faq -->
			<section class="faq-sec faq-sec-two">
				<div class="container">
					<div class="course-two-bg">
						<img src="@/assets/img/bg/home-two-bg-09.png" class="img-fluid feature-bgone" alt="">
						<img src="@/assets/img/bg/home-two-bg-10.png" class="img-fluid feature-bgtwo" alt="">
					</div>
					<!-- Section Header -->
					<div class="section-header section-head-one text-center aos " data-aos="fade-up">
						<h2>Frequently Asked Questions</h2>
						<div class="title-bar">
							<img src="@/assets/img/icon/index-title-bar.svg" alt="">
						</div>
						<p class="sub-title">They are highly aWe collect reviews from our users so you can get an </p>
					</div>
					<!-- /Section Header -->
					<div class="row">
						<div class="col-md-12">
							<div class="faq-card">
								<div class="two-faq">
									<a class="collapsed" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
										<span> How can I book?</span>                                                                                                                                                                                     
										<div class="collapse multi-collapse" id="multiCollapseExample1">
											<div class="collapse-para">
												Lorem ipsum dolor sit amet consectetur. In nibh enim pretium vitae velit. Laoreet nibh et ultricies gravida sit elit enim metus donec. Eleifend vitae arcu enim est. Massa nibh viverra pulvinar cras nisl congue viverra. Cras est eu morbi non consectetur. Enim viverra interdum lorem turpis vel at. Nunc dignissim turpis lacinia varius purus congue massa. Ac faucibus felis sed amet. Cursus ut aliquam sit convallis. Luctus purus curabitur vivamus 
											</div>
										</div>
									</a>
								</div>
								<div class="two-faq">
									<a class="collapsed" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="true" aria-controls="multiCollapseExample2">
										<span> Quisque dignissim vulputate purus in sodales?</span>                                                                                                                                                                                   
										<div class="collapse multi-collapse" id="multiCollapseExample2">
											<div class="collapse-para">
												Lorem ipsum dolor sit amet consectetur. In nibh enim pretium vitae velit. Laoreet nibh et ultricies gravida sit elit enim metus donec. Eleifend vitae arcu enim est. Massa nibh viverra pulvinar cras nisl congue viverra. Cras est eu morbi non consectetur. Enim viverra interdum lorem turpis vel at. Nunc dignissim turpis lacinia varius purus congue massa. Ac faucibus felis sed amet. Cursus ut aliquam sit convallis. Luctus purus curabitur vivamus 
											</div>
										</div>
									</a>
								</div>
								<div class="two-faq">
									<a class="collapsed" data-bs-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="true" aria-controls="multiCollapseExample3">
										<span> Nullam non vehicula neque. Vivamus sed consectetur nunc. Proin eu tristique justo, id eleifend urna? </span>                                                                                                                                                                                     
										<div class="collapse multi-collapse" id="multiCollapseExample3">
											<div class="collapse-para">
												Lorem ipsum dolor sit amet consectetur. In nibh enim pretium vitae velit. Laoreet nibh et ultricies gravida sit elit enim metus donec. Eleifend vitae arcu enim est. Massa nibh viverra pulvinar cras nisl congue viverra. Cras est eu morbi non consectetur. Enim viverra interdum lorem turpis vel at. Nunc dignissim turpis lacinia varius purus congue massa. Ac faucibus felis sed amet. Cursus ut aliquam sit convallis. Luctus purus curabitur vivamus 
											</div>
										</div>
									</a>
								</div>
								<div class="two-faq">
									<a class="collapsed" data-bs-toggle="collapse" href="#multiCollapseExample4" role="button" aria-expanded="false" aria-controls="multiCollapseExample4">
										<span> Lorem Ipsum is simply dummy text of the printing and typesetting?</span>                                                                                                                                                                                  
										<div class="collapse multi-collapse" id="multiCollapseExample4">
											<div class="collapse-para">
												Lorem ipsum dolor sit amet consectetur. In nibh enim pretium vitae velit. Laoreet nibh et ultricies gravida sit elit enim metus donec. Eleifend vitae arcu enim est. Massa nibh viverra pulvinar cras nisl congue viverra. Cras est eu morbi non consectetur. Enim viverra interdum lorem turpis vel at. Nunc dignissim turpis lacinia varius purus congue massa. Ac faucibus felis sed amet. Cursus ut aliquam sit convallis. Luctus purus curabitur vivamus 
											</div>
										</div>
									</a>
								</div>
								<div class="two-faq">
									<a class="collapsed mb-0" data-bs-toggle="collapse" href="#multiCollapseExample5" role="button" aria-expanded="false" aria-controls="multiCollapseExample5">
										<span> Sit amet pulvinar metus est ut nisl? </span>                                                                                                                                                                                     
										<div class="collapse multi-collapse" id="multiCollapseExample5">
											<div class="collapse-para">
												Lorem ipsum dolor sit amet consectetur. In nibh enim pretium vitae velit. Laoreet nibh et ultricies gravida sit elit enim metus donec. Eleifend vitae arcu enim est. Massa nibh viverra pulvinar cras nisl congue viverra. Cras est eu morbi non consectetur. Enim viverra interdum lorem turpis vel at. Nunc dignissim turpis lacinia varius purus congue massa. Ac faucibus felis sed amet. Cursus ut aliquam sit convallis. Luctus purus curabitur vivamus 
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Faq -->
    </template>