<template>
    			<!-- Student Testimonial -->
			<section class="student-testimonial">
				<div class="success-story">
					<div class="container">
						<div class="section-header-eight text-center aos" data-aos="fade-up">
							<span>Testimonials</span>
							<h2>Our Students Success Stories</h2>
							<p class="sub-title">Are you looking to join online institutions? Now it's very simple, Sign up with mentoring</p>
							<div class="sec-dots">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
						<div class="student-online-testi">
							<div class="row align-items-center">
								<div class="col-lg-6">
									<div class="video-img video-img-big" data-aos="fade-up">
										<div class="testi-img">
											<a  href="javascript:void(0);"><img src="@/assets/img/city/student-testimonial-01.jpg" class="img-fluid" alt=""></a>
										</div>
										<div class="play-icon">
											<a href="https://www.youtube.com/watch?v=u-Hob5pjJSA" data-fancybox><i class="fas fa-play"></i></a>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="testi-review" data-aos="fade-up">
										<span class="quatation-icon">
											<img src="@/assets/img/icon/quatation-icon.svg" alt="">
										</span>
										<div class="testi-content">
											<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae neque metus. Vivamus consectetur ultricies commodo. Pellentesque at nisl sit amet.</p>
										</div>
										<div class="testi-rating">
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
											<i class="fas fa-star filled"></i>
										</div>
										<h5><a href="javascript:void(0);">Devon Nick</a></h5>
										<span>USA</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="multiple-students">
						<div class="row">
							<div class="col-md-3 col-sm-6">
								<div class="video-img" data-aos="fade-up">
									<div class="testi-img">
										<a href="javascript:void(0);"><img src="@/assets/img/city/student-testimonial-02.jpg" class="img-fluid" alt=""></a>
									</div>
									<div class="play-icon">
										<a href="https://www.youtube.com/watch?v=u-Hob5pjJSA" data-fancybox><i class="fas fa-play"></i></a>
									</div>
								</div>
							</div>
							<div class="col-md-3 col-sm-6">
								<div class="video-img">
									<div class="testi-img" data-aos="fade-up">
										<a href="javascript:void(0);"><img src="@/assets/img/city/student-testimonial-03.jpg" class="img-fluid" alt=""></a>
									</div>
									<div class="play-icon">
										<a href="https://www.youtube.com/watch?v=u-Hob5pjJSA" data-fancybox><i class="fas fa-play"></i></a>
									</div>
								</div>
							</div>
							<div class="col-md-3 col-sm-6">
								<div class="video-img">
									<div class="testi-img" data-aos="fade-up">
										<a href="javascript:void(0);"><img src="@/assets/img/city/student-testimonial-04.jpg" class="img-fluid" alt=""></a>
									</div>
									<div class="play-icon">
										<a href="https://www.youtube.com/watch?v=u-Hob5pjJSA" data-fancybox><i class="fas fa-play"></i></a>
									</div>
								</div>
							</div>
							<div class="col-md-3 col-sm-6">
								<div class="video-img" data-aos="fade-up">
									<div class="testi-img">
										<a href="javascript:void(0);"><img src="@/assets/img/city/student-testimonial-05.jpg" class="img-fluid" alt=""></a>
									</div>
									<div class="play-icon">
										<a href="https://www.youtube.com/watch?v=u-Hob5pjJSA" data-fancybox><i class="fas fa-play"></i></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Student Testimonial -->
    </template>